import { template as template_078199223675491ca95e168b8fa08f22 } from "@ember/template-compiler";
const WelcomeHeader = template_078199223675491ca95e168b8fa08f22(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
