import { template as template_879e4d80c9bb4cc2b6fb9b7e5c13b7ee } from "@ember/template-compiler";
const EmptyState = template_879e4d80c9bb4cc2b6fb9b7e5c13b7ee(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
