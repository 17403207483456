import { template as template_25d289bea5704455b56fdf6ba60f426e } from "@ember/template-compiler";
const SidebarSectionMessage = template_25d289bea5704455b56fdf6ba60f426e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
